import { LazyMotion } from 'framer-motion';

// https://www.framer.com/motion/guide-reduce-bundle-size/

const loadFeatures = () =>
	import('./motion-features.js').then((res) => res.default);

export default function MotionWrapper({ children }) {
	return (
		<LazyMotion features={loadFeatures} strict>
			{children}
		</LazyMotion>
	);
}
