module.exports.SITE = {
	name: 'Shockwave Jujitsu',
	origin: 'https://www.shockwavejujitsu.co.uk',
	basePathname: '/',
	trailingSlash: false,
	title: 'Shockwave Jujitsu - Self-Defence Classes in Ipswich',
	description:
		'Become that rare person who can handle themselves in any threatening situation.',
	email: 'info@shockwavejujitsu.co.uk',
	phone: '07791068625',
	location: 'Murrayside Community Centre',
	locationAddressFull:
		'Murrayside Community Centre, Nacton Road, Ipswich, IP3 9JL',
	locationAddressShort: 'Murrayside Community Centre, Ipswich, IP3 9JL',
	locationMapLinkLabel: 'Map & Directions',
	locationMapLink: 'https://maps.app.goo.gl/GuTZcPbmS6pBF1Kj6',
	locationMapSideEntranceLink: 'https://maps.app.goo.gl/AVSwoqgAhpu1UYf27',
	facebookLink: 'https://www.facebook.com/shockwavejujitsu',
	pageTitle:
		'Mixed Martial Arts Training for Self-Defence | Shockwave Jujitsu',
	metaDescription:
		'Join our beginner-friendly self-defence classes in Ipswich and become that rare person who can handle themselves in any threatening situation.',
	homePageTitle:
		'Mixed Martial Arts Training for Self-Defence | Shockwave Jujitsu',
	homeMetaDescription:
		'Join our beginner-friendly self-defence classes in Ipswich and become that rare person who can handle themselves in any threatening situation.',
	aboutPageTitle:
		'About Shockwave Jujitsu and Close Quarters Self-Defence? | Shockwave Jujitsu',
	aboutMetaDescription:
		'Join our beginner-friendly self-defence classes in Ipswich and meet like-minded people learning mixed martial arts for close quarters self-defence.',
	syllabusPageTitle:
		'Our Syllabus: Mixed Martial Arts Training for Self-Defence | Shockwave Jujitsu',
	syllabusMetaDescription:
		'Explore the five skillsets that will prepare you to prevail against modern day violence. Join our next 4 Weeks Beginners Course to sample the syllabus.',
	classesPageTitle:
		'Mixed Martial Arts Self-Defence Classes in Ipswich | Shockwave Jujitsu',
	classesMetaDescription:
		'Discover what to expect on your free trial class on Sunday 10:00-12:00 at the Murrayside Community Centre, Ipswich. No martial arts experience is required.',
	contactPageTitle: 'Contact Our Head Instructor | Shockwave Jujitsu',
	contactMetaDescription:
		'Contact us with any questions not answered by our FAQs.',
	coursesPageTitle:
		'4 Week Beginners Self-Defence Courses | Shockwave Jujitsu',
	coursesMetaDescription:
		'Join our next 4 week Beginners Course. Our course is ideal if you have previously dismissed the idea of yourself as a martial arts person.',
	faqsPageTitle:
		'FAQs To Help You Choose A Martial Arts School | Shockwave Jujitsu',
	faqsMetaDescription:
		'Read our comprehensive FAQs to check that our Mixed Martial Arts Self-Defence training is a good fit for you.',
	reviewsPageTitle: 'Reviews From Our Students | Shockwave Jujitsu',
	reviewsMetaDescription:
		'Read what our students have to say about the quality of self-defence training they have received.',
	workshopsPageTitle:
		'Self-Defence Workshops For Public-Facing Workers | Shockwave Jujitsu',
	workshopsMetaDescription:
		'Book self-defence training for your public-facing employees to demonstrate you care about their personal safety and wellbeing at work.',
};
